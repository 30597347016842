<div class="m-6">
  <h2>{{ 'General.SelectLanguage' | translate }}</h2>
  <br />
  <button
    class="rounded border py-4 px-6 min-w-[5rem] mr-2 bg-white hover:bg-neutral-200"
    [class.active]="translate.currentLang === 'en'"
    (click)="this.translate.use('en')"
  >
    en
  </button>
  <button
    class="rounded border py-4 px-6 min-w-[5rem] bg-white hover:bg-neutral-200"
    [class.active]="translate.currentLang === 'de'"
    (click)="this.translate.use('de')"
  >
    de
  </button>

  <br />
  <br />

  <label>browser-Language: {{ this.browserLang }}</label>
  <br />
  <label>selected-Language: {{ this.translate.currentLang }}</label>
  <br />
  <br />
  <hr />
  <br />

  <h2>{{ 'Activity.create' | translate }}</h2>
  <br />

  <form [formGroup]="formGroupActivity" (ngSubmit)="onSubmit()">
    <div class="form-field">
      <div class="input-masked"></div>
      <textarea
        formControlName="textareaToken"
        id="idToken"
        [class.has-value]="formGroupActivity.get('textareaToken')?.value"
      ></textarea>
      <label for="idToken">idToken (http, Bearer)</label>
    </div>

    <div class="form-field">
      <div class="input-masked"></div>
      <textarea
        formControlName="textareaAddress"
        id="textareaAddressId"
        [class.has-value]="formGroupActivity.get('textareaAddress')?.value"
      ></textarea>
      <label for="textareaAddressId">{{
        'Activity.address' | translate
      }}</label>
    </div>

    <div class="form-field">
      <div class="input-masked"></div>
      <textarea
        formControlName="textareaUser"
        id="textareaUserId"
        [class.has-value]="formGroupActivity.get('textareaUser')?.value"
      ></textarea>
      <label for="textareaUserId">{{ 'Activity.user' | translate }}</label>
    </div>

    <button
      class="rounded py-4 px-6 mr-2 bg-yellow-400 hover:bg-yellow-300"
      type="submit"
    >
      {{ 'Activity.create' | translate }}
    </button>
    <button
      class="rounded py-4 px-6 bg-yellow-400 hover:bg-yellow-300"
      (click)="presetControlValues()"
    >
      {{ 'Activity.setPresets' | translate }}
    </button>
  </form>

  <ec-action-create-activity-modal
    #activity
    [language]="translate.currentLang"
    [addresses]="currentAddressModel"
    [user]="currentUserModel"
    (closeEvent)="modalClose()"
    (errorEvent)="createActivityErrorMessage($event)"
    (successEvent)="creationSuccess()"
  ></ec-action-create-activity-modal>

  <ec-shared-alert-box
    *ngIf="!!this.errorMessage"
    class="mt-4 mb-4"
    variant="info"
    [message]="this.errorMessage"
  ></ec-shared-alert-box>
</div>
