import {
  Component,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'iwt-action-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  browserLang: string;
  formGroupActivity: FormGroup;
  currentAddressModel = [{}];
  currentUserModel = {};
  errorMessage = '';
  currentTokenMock = '';
  addressModelMock =
    '[{"entityUuid": "9339ad56-31e6-4db7-9875-339cbd734387", ' +
    '"recordType": "Person",' +
    '"companyName": "addressCompanyName",' +
    '"firstName": "addressFirstName",' +
    '"lastName": "addressLastName"}]';
  userModelMock =
    '{"entityUuid": "06ec0892-4ab9-4fd4-a5d3-45fbf4cb4ad0","firstName": "firstName","lastName": "lastName"}';

  @ViewChild('activity') activityComponent: ElementRef;

  constructor(public translate: TranslateService) {
    this.initTranslateService();
    this.addComponents();

    this.formGroupActivity = new FormGroup({
      textareaAddress: new FormControl(
        this.addressModelMock,
        Validators.minLength(10)
      ),
      textareaUser: new FormControl(
        this.userModelMock,
        Validators.minLength(10)
      ),
      textareaToken: new FormControl(this.currentTokenMock),
    });
  }

  addComponents() {
    const href = document.location.href.startsWith('http://localhost')
      ? 'http://localhost:3001/'
      : document.location.href;
    const urls = [
      'https://move.dev.estatepro.de/shared-components.js',
      `${href}activity-components.js`,
    ];

    const existingElement = document.getElementsByTagName('script')[0];
    urls.forEach((url) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = url;
      existingElement?.parentNode?.insertBefore(script, existingElement);
    });
  }

  setAddress() {
    const value = this.formGroupActivity.get('textareaAddress')?.value;
    this.currentAddressModel = JSON.parse(value);
  }

  setUser() {
    const value = this.formGroupActivity.get('textareaUser')?.value;
    this.currentUserModel = JSON.parse(value);
  }

  modalClose() {
    console.log('modal close event');
  }

  getVariant(language: string): 'primary' | 'secondary' {
    return this.translate.currentLang == language ? 'primary' : 'secondary';
  }

  onSubmit(): void {
    this.errorMessage = '';
    this.setAddress();
    this.setUser();
    const token = this.formGroupActivity.get('textareaToken')?.value;
    this.activityComponent.nativeElement.authorizationToken = token;
    if (token) {
      this.activityComponent.nativeElement.open = true;
    }
  }

  presetControlValues() {
    this.formGroupActivity.patchValue({
      textareaAddress: this.addressModelMock,
      textareaUser: this.userModelMock,
      textareaToken: this.currentTokenMock,
    });

    if (this.formGroupActivity.get('textareaToken')?.invalid) {
      this.formGroupActivity.patchValue({ textareaToken: '' });
    }
  }

  initTranslateService() {
    this.translate.addLangs(['de', 'en']);
    this.translate.setDefaultLang('en');
    this.browserLang = this.translate.getBrowserLang() ?? 'en';
    this.translate.use(
      this.browserLang.match(/en|de/) ? this.browserLang : 'en'
    );
  }

  createActivityErrorMessage(e: Event) {
    const msg = e as CustomEvent;
    if (!msg?.detail) {
      return;
    }

    this.errorMessage = msg.detail.message;
  }

  creationSuccess(): void {
    console.log('Activity successfully saved');
  }
}
